try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');

	require('bootstrap');

	require('./modules/cookies.js');
} catch (e) {}

// window.Fortawefsome = require('@fortawesome/fontawesome-pro/js/all');

// Vue
// window.Vue = require('vue');

// Axios
window.axios = require('axios');

// momentjs
window.moment = require('moment');
window.moment.locale('de-at');

// lax.js
// import lax from 'lax.js'
window.lax = require('lax.js');
window.owlCarousel = require('owl.carousel');

window.onload = function() {
	window.lax.init();
	// // Add a driver that we use to control our animations
	window.lax.addDriver('scrollY', function(frame) {
		return window.scrollY;
	});

	lax.addElements(
		'.lax-scrollY-translateY-up', // Element selector rule
		{
			scrollY: { // Driver name
				translateY: [ // CSS property
					['elInY', 'elOutY'], // Driver value map
					[0, -250], // Animation value map
					{
						inertia: 10 // Options
					}
				]
			}
		}
	);
	lax.addElements(
		'.lax-scrollY-translateY-up-limit', // Element selector rule
		{
			scrollY: { // Driver name
				translateY: [ // CSS property
					['elInY', 'elOutY'], // Driver value map
					[70, -110], // Animation value map
					{
						inertia: 10 // Options
					}
				]
			}
		}
	);
	lax.addElements(
		'.lax-scrollY-translateY-down', // Element selector rule
		{
			scrollY: { // Driver name
				translateY: [ // CSS property
					['elInY', 'elOutY'], // Driver value map
					[0, 25], // Animation value map
					{
						inertia: 10 // Options
					}
				]
			}
		}
	);
	lax.addElements(
		'.lax-scrollY-slideIn', // Element selector rule
		{
			scrollY: { // Driver name
				translateX: [ // CSS property
					['elInY', 'elCenterY-400', 'elOutY'], // Driver value map
					['elWidth', 0], // Animation value map
					{
						inertia: 80 // Options
					}
				]
			}
		}
	);


	$(".team").html($(".team .team-item").sort(function() {
		return Math.random() - 0.5;
	}));
};


// Vue.component('project-form', require('./components/ProjectForm.vue').default);
//
// const app = new Vue({
// 	el: '#app',
// 	components: {
// 		//
// 	}
// });




// jQuery should work here xD

const Imbasto = window.Imbasto = {};
console.log('imbasto init()');

$(function() {
	$('[data-toggle="tooltip"]').tooltip()
});

/*
let Backdoor = window.Backdoor = $('#backdoor');

window.addEventListener('scroll', function() {
	if (window.pageYOffset >= 150) {
		Backdoor.addClass('entrance');
	} else {
		Backdoor.removeClass('entrance');
	}
});
*/

Imbasto.switchImbastoServiceBlock = function(element) {
	let button = $(element);
	$('.imbasto-service-block').removeClass('active');
	$('.imbasto-service-switch').removeClass('active');
	button.addClass('active');
	$('#' + button.attr('data-active-id')).addClass('active');
};

function randomizeTeam() {
	if(!document.getElementById("new_worker_img")){return true}
	document.getElementById("new_worker_img").src = `/images/team/bewerber/newwork${Math.floor(Math.random() * Math.floor(6))}.svg`;
	return true;
}

function elementInViewport(el) {
	let top = el.offsetTop;
	let left = el.offsetLeft;
	let width = el.offsetWidth;
	let height = el.offsetHeight;

	while (el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
		left += el.offsetLeft;
	}

	return (
		top < (window.pageYOffset + window.innerHeight) &&
		left < (window.pageXOffset + window.innerWidth) &&
		(top + height) > window.pageYOffset &&
		(left + width) > window.pageXOffset
	);
}
randomizeTeam();

// let inCurrentView = false;
// window.addEventListener('scroll', function() {
// 	let checker = elementInViewport(document.getElementById('team_block'));
// 	if (inCurrentView !== checker) {
// 		inCurrentView = checker === true ? randomizeTeam() : false;
// 	}
// });

let contactForm = document.getElementById("imbasto-contact-form");

if(contactForm) {
	contactForm.addEventListener("submit",function(e){
		console
		e.preventDefault();
		sendContactFrom();
	})
}

// Send Kontakt Formular
sendContactFrom = function() {
	console.log('sendForm');

	let url = "/api/sendcontactmail.php";
	let formData = {}; // $('#imbasto-contact-form').serializeArray();
	$("#imbasto-contact-form").serializeArray().map(function(x) {
		formData[x.name] = x.value;
	});

	console.log(url, formData);

	axios.post(url, formData)
		.then(function(response) {
			// handle success
			console.log("success", response);
			if (response.data.success && response.data.error == null) {
				$('#contact-card').html(response.data.message);
			} else {
				//
			}
		})
		.catch(function(error) {
			// handle error
			console.log("error", error);
		});
};

function leistugenCarousel() {
	var checkWidth = $(window).width();
	var owlPost = $(".carousel");
	if (checkWidth >= 768) {
	  if (typeof owlPost.data('owl.carousel') != 'undefined') {
		owlPost.addClass("lax-scrollY-slideIn");
		owlPost.data('owl.carousel').destroy();
	}
	owlPost.removeClass('owl-carousel');
} else if (checkWidth < 768) {
		owlPost.removeClass("lax-scrollY-slideIn");
		owlPost.addClass('owl-carousel');
		owlPost.owlCarousel({
			items: 1,
			slideSpeed: 200,
			stagePadding: 32,
			// center : true,
			touchDrag: true,
			margin : 12,
			autoplay : true,
			autoplayHoverPause: true,
			autoplayTimeout : 7000,
			autoplaySpeed : 900,
			mouseDrag: true,
		});
	}
  }

  leistugenCarousel();
  $(window).resize(leistugenCarousel);


$('.showcase-carousel').owlCarousel({
		items: 4,
		slideSpeed: 200,
		stagePadding: 32,
		center : true,
		touchDrag: true,
		margin : 12,
		autoplay : true,
		autoplayHoverPause: true,
		autoplayTimeout : 3000,
		autoplaySpeed : 900,
	mouseDrag: true,
	autoHeight: false,
	nav: true,
	loop: true,
	navText : ["<i class='fal fa-lg fa-arrow-left'></i>","<i class='fal fa-lg fa-arrow-right'></i>"]
	});
