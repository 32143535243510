;

function removeCookies() {
	let cookies = document.cookie.split(';');
	cookies.forEach((cookie, cookieIndex) => {
		let dataArray = cookie.split('=');
		console.log('cookiename', dataArray[0]);
		if (dataArray[0].trim() !== "XSRF-TOKEN") {
			console.log('delete Cookie', dataArray[0]);
			document.cookie = dataArray[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.imbasto.at";
		}
	});
}

window.loadGTMAsync = function(w, d, s, l, i) {
	console.log('Google Tag Manager');
	w[l] = w[l] || [];
	w[l].push({
		'gtm.start': new Date().getTime(),
		event: 'gtm.js'
	});
	let f = d.getElementsByTagName(s)[0],
		j = d.createElement(s),
		dl = l !== 'dataLayer' ? '&l=' + l : '';
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
	f.parentNode.insertBefore(j, f);
};
window.cookieConsent = function(iWantCookies) {
	let cookie = {
		cookies: iWantCookies,
		time: Date.now()
	};
	if (iWantCookies) {
		// $('#cookie_consent_banner').hide();
		$('#cookie_consent_banner').addClass('burstAway')
		localStorage.setItem('cookieCat', JSON.stringify(cookie));
		window.loadGTMAsync(window, document, 'script', 'dataLayer', 'GTM-WQNQSZM');
	} else {
		console.log('i dont want cookies');
		$('#cookie_consent_banner').addClass('wobbleAway');
		localStorage.setItem('cookieCat', JSON.stringify(cookie));
		// removeCookies();
	}
};
window.resetCookieConsent = function() {
	localStorage.removeItem('cookieCat');
	$('#cookie_consent_banner').show();
	$('#cookie_consent_banner').removeClass('wobbleAway').removeClass('burstAway');
};

(function() {
	let cookieCat = localStorage.getItem('cookieCat');
	if (cookieCat == null) {
		$('#cookie_consent_banner').show();
	} else {
		let jsonCookieCat = JSON.parse(cookieCat);
		console.log("jsonCookieCat", jsonCookieCat);
		if (jsonCookieCat != null && jsonCookieCat.cookies === true) {
			window.loadGTMAsync(window, document, 'script', 'dataLayer', 'GTM-WQNQSZM');
		} else if (jsonCookieCat != null && jsonCookieCat.cookies === false) {
			let now = Date.now();
			console.log("timecheck", now - jsonCookieCat.time);
			if (now - jsonCookieCat.time >= 604800000) {
				window.resetCookieConsent();
			}
		}
	}
})();